<template>
  <div>
    <v-text-field
      :key="item.id"
      v-model="quantity"
      v-bind="$attrs"
      placeholder="Units"
      step="1"
      prepend-icon="mdi-minus"
      append-outer-icon="mdi-plus"
      hide-details
      :error-messages="error"
      v-on="$listeners"
      @click:prepend="remove"
      @click:append-outer="add"
      @input="updateValue"
    >
    </v-text-field>
    <div v-if="maximum" class="text-body-2 text-center">
      {{ hint | titleCase }}
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    item: {
      type: Object,
      required: true
    },
    maximum: {
      type: Boolean,
      default: false
    },
    unitsOnOrder: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      quantity: 0,
      error: null,
      max: this.setMax(),
      itemId: null
    }
  },
  computed: {
    hint() {
      return this.$t('hints.maxItemsInStock', { max: this.max })
    }
  },
  watch: {
    itemId(newValue, oldValue) {
      if (newValue === oldValue) return
      this.init()
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.itemId = this.item.id
      if (this.item.attributes.units) this.quantity = this.item.attributes.units
      if (this.unitsOnOrder) {
        this.item.attributes.quantity = 0
        return this.setMax()
      }
      if (this.item.attributes.itemUnitsResponseCount) {
        this.quantity = this.item.attributes.itemUnitsResponseCount
        this.item.attributes.quantity = this.quantity
      } else {
        this.quantity = 1
        this.item.attributes.quantity = this.quantity
      }
      this.setMax()
    },
    setMax() {
      this.max = this.maximum
        ? this.item.attributes.itemUnitsInStockCount + this.quantity
        : 30000
    },
    add() {
      this.error = null
      if (this.quantity + 1 > this.max)
        return (this.error = this.$t('alerts.outOfStockMessage'))
      this.quantity += 1
      this.item.attributes.quantity = this.quantity
      this.$emit('change')
    },
    remove() {
      this.error = null
      if (this.quantity - 1 < 0) return
      this.quantity -= 1
      this.item.attributes.quantity = this.quantity
      this.$emit('change')
    },
    updateValue(number) {
      const num = Number(number)
      this.quantity = num
      this.item.attributes.quantity = num
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
