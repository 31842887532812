<template>
  <div>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          {{ $t('actions.edit') }} {{ $t('item.quantity') }}
        </v-card-title>

        <v-card-text>
          <QuantityField
            v-if="editedItem"
            :key="editedItem.id"
            class="mt-5"
            :units-on-order="true"
            dense
            filled
            :item="editedItem"
          />
          <div v-else class="text-center">
            <v-progress-circular
              class="text-center"
              indeterminate
              size="36"
            ></v-progress-circular>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            light
            depressed
            color="grey lighten-1"
            outlined
            @click="onReset"
          >
            {{ $t('actions.cancel') }}
          </v-btn>
          <v-btn color="primary" depressed @click="onSubmit">
            {{ $t('actions.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import QuantityField from '@/components/QuantityField'
import { mapActions } from 'vuex'
export default {
  components: {
    QuantityField
  },
  props: {
    taskId: {
      type: String,
      required: true
    },
    outputItemId: {
      type: String,
      default: ''
    },
    inputItemId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dialog: false,
      editedItem: null
    }
  },

  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler(route) {
        this.dialog = route.meta.dialog
        if (this.dialog) this.fetchItem()
      }
    }
  },
  methods: {
    ...mapActions({
      fetchInputItem: 'inputItem/fetch',
      fetchOutputItem: 'outputItem/fetch',
      inputItemUpdate: 'inputItem/update',
      outputItemUpdate: 'outputItem/update'
    }),
    async fetchItem() {
      const fn =
        this.$route.meta.module === 'inputItem'
          ? this.fetchInputItem
          : this.fetchOutputItem

      this.editedItem = await fn({
        taskId: this.taskId,
        taskItemId: this.outputItemId || this.inputItemId
      })
    },

    async onSubmit() {
      const fn =
        this.$route.meta.module === 'inputItem'
          ? this.inputItemUpdate
          : this.outputItemUpdate

      // Quick fix between units / quantities
      this.editedItem.attributes.units = this.editedItem.attributes.quantity
      await fn({
        taskId: this.taskId,
        item: this.editedItem
      })
      this.onReset()
    },
    onReset() {
      this.dialog = true
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped></style>
